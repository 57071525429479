import React from "react";
import { Nav, Image, NavDropdown, Navbar } from "react-bootstrap";
import "./styles/Header.css";
import pageIcon from "../assets/images/indya-logo.webp";

const Header = (): JSX.Element => {
  return (
    <React.Fragment>
      <header className="d-flex flex-column flex-sm-row flex-wrap justify-content-center align-items-center py-2 mb-0 border-bottom text-uppercase">
        <Navbar.Brand
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none
          mx-3"
        >
          <Image src={pageIcon} alt="Indya Logo" width="50.3" height="35"/>
          <span className="mx-2 fw-bold">Terapias Florales Indya</span>
        </Navbar.Brand>
        <Nav as="ul" className="d-flex flex-column flex-sm-row flex-wrap align-items-center text-capitalize" variant="pills" defaultActiveKey="/home">
          <Nav.Item as="li">
            <Nav.Link eventKey="home" href="/" aria-current="page" aria-label="Home">
              Inicio
            </Nav.Link>
          </Nav.Item>
          <NavDropdown title="Productos" id="navbarScrollingDropdown">
            <NavDropdown.Item as="li">
              <Nav.Link eventKey="bachflowers" href="/bachflowers" aria-label="Flores de Bach">
                Flores de Bach
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Nav.Link eventKey="bushflowers" href="/bushflowers" aria-label="Flores de Bush">
                Flores de Bush
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Nav.Link eventKey="eyemasks" href="/eyemasks" aria-label="Máscaras de semillas">
                Máscaras de semillas
              </Nav.Link>
            </NavDropdown.Item>
            <NavDropdown.Item as="li">
              <Nav.Link eventKey="candles" href="/candles" aria-label="Velas Artesanales">
                Velas artesanales
              </Nav.Link>
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Item as="li">
            <Nav.Link eventKey="courses" href="/courses" aria-label="Cursos">
              Cursos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link eventKey="aboutus" href="/aboutus" aria-label="Sobre nosotros">
              Sobre mí
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </header>
    </React.Fragment>
  );
};

export default Header;
