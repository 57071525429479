import React from "react";
// import {Image } from "react-bootstrap";
import "./styles/Hero.css";
import heroIcon from "../assets/images/indya-logo.webp"

function Hero(): JSX.Element {
  return (
    <React.Fragment>
      <div id="Hero__image" title="Main Hero Image" className="px-4 py-5 my-4 text-center">
        <source srcSet={heroIcon}type="image/webp"/>
        {/* <Image
          className="d-block mx-auto mb-4"
          src={heroIcon}
          alt="Hero image"
          width="82"
          height="57"
        /> */}
        <h1 className="display-2 fw-bold" id="main_title">
          Terapias Florales Indya
        </h1>

      </div>
    </React.Fragment>
  );
}

export default Hero;
