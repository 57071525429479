import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BachFlowers from "../pages/BachFlowers";
import BushFlowers from "../pages/BushFlowers";
import Layout from "./Layout";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import SoothingEyeMasks from "../pages/SoothingEyeMasks";
import AboutUs from "../pages/AboutUs";
import Courses from "../pages/Courses";
import Candles from "../pages/Candles";
import "./styles/App.css";
import ScrollToTop from "./ScrollToTop";

function App(): JSX.Element {
  return (
    <Router>
      <ScrollToTop>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bachflowers" element={<BachFlowers />} />
            <Route path="/bushflowers" element={<BushFlowers />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/eyemasks" element={<SoothingEyeMasks />} />
            <Route path="/candles" element={<Candles />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </ScrollToTop>
    </Router>
  );
}

export default App;
