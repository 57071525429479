import React from "react";
//import ReactDOM from "react-dom";
import App from "./components/App";
//import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/custom.scss";

/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
*/

import { createRoot } from "react-dom/client";

// Define page html language
window.document.documentElement.lang = "es";
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
