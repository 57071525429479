import React from "react";
import { Card, Button } from "react-bootstrap";
import ProductItem from "../model/ProductItem";
import ProductCardDetails from "./ProductCardDetails";

function ProductCard(props: { item: ProductItem}): JSX.Element {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <React.Fragment>
      <Card style={{ width: "18rem" }}>
        <Card.Img
          variant="top"
          src={`./images/${props.item.image}`}
          alt={props.item.alternativeText}
          width="100%"
          height="auto"
        />
        <Card.Body>
          <Card.Title>{props.item.title}</Card.Title>
          <Card.Subtitle className="my-2">{props.item.subtitle}</Card.Subtitle>
          <Card.Text>{props.item.briefDescription}</Card.Text>
          <Button variant="primary" onClick={() => setModalShow(true)}>Detalles</Button>
          <ProductCardDetails item={props.item} show={modalShow} onHide={() => setModalShow(false)}/>
        </Card.Body>
        <Card.Footer className="text-center">
          {/* $ {props.item.price.toFixed(2)} */}
          Consultar disponibilidad
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}

export default ProductCard;
