import React from "react";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import SimpleHero from "../components/SimpleHero";
import data from "../assets/data/bach.json";

function BachFlowers(): JSX.Element {

  return (
    <React.Fragment>
      <SimpleHero
        title="Flores de Bach"
        description="Las Flores de Bach son una serie de esencias naturales utilizadas para tratar diversas situaciones emocionales; como miedos, soledad, desesperación, estrés, panico, depresión y obsesiones"
        image="tulip-magnolia.webp"
      />
      <Container>
        <Container>
          <section>
            <h1>¿Qué son las flores de Bach?</h1>
            <p>
              Fueron descubiertas por Edward Bach entre los años 1926 y 1934. El
              Dr.Bach era un gran investigador, además de médico y homeópata.
              Se encuentran dentro del grupo de las llamadas terapias alternativas,
              se utilizan tanto para enfermedades físicas o psicosomáticas como
              para trastornos psicológicos o emocionales.
            </p>
            <p>
              El uso de las flores con fines terapéuticos se remonta a la antigua China, no
              fue hasta 1930 cuando el médico británico Edward Bach dio a
              conocer un sistema terapéutico basado en la utilización de 38
              remedios naturales que se preparan con flores frescas, maceradas
              en agua mineral y a la luz del sol, que conservan la cualidad.
              Su teoría era que las enfermedades físicas tienen un origen
              emocional, y que si los conflictos emocionales subsisten por mucho
              tiempo, la enfermedad del cuerpo empieza a aparecer.
            </p>
          </section>
          <br></br>
        </Container>
        <Container>
          <CardGroup className="ProductList">
            <Row xs={1} md={4} className="g-4 m-3">
              {data.Products.map((item) => (
                <Col key={item.id}>
                  <ProductCard item={item} />
                </Col>
              ))}
            </Row>
          </CardGroup>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default BachFlowers;
