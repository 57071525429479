import React from "react";
import { Nav } from "react-bootstrap";
import { Whatsapp, Instagram, Facebook } from "react-bootstrap-icons";
import "./styles/Footer.css";

function Footer(): JSX.Element {
  return (
    <React.Fragment>
      <footer className="d-flex flex-wrap flex-row justify-content-sm-between justify-content-xs-center align-items-center py-3 border-top mb-1">
        <div className="col-md-4 d-flex align-items-center">
          <span>Terapias Florales Indya ©2021-2024. Todos los derechos reservados.</span>
        </div>
        <Nav
          as="ul"
          className="col-md-4 justify-content-end list-unstyled d-flex flex-row"
        >
          <Nav.Item as="li" className="ms-3">
            <a
              href="https://api.whatsapp.com/send?phone=541163772420&text=Hola%20Ivana%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20las%20Terapias%20Florales"
              target="_blank"
              aria-label="Link a WhatsApp"
              rel="noopener noreferrer"
            >
              <Whatsapp size={20} />
            </a>
          </Nav.Item>
          <Nav.Item as="li" className="ms-3">
            <a
              href="https://www.instagram.com/terapiafloralindya/"
              target="_blank"
              aria-label="Instagram Page"
              rel="noopener noreferrer"
            >
              <Instagram size={20} />
            </a>
          </Nav.Item>
          <Nav.Item as="li" className="ms-3">
            <a
              href="https://www.facebook.com/IvanaGerpe"
              target="_blank"
              aria-label="Facebook Page"
              rel="noopener noreferrer"
            >
              <Facebook size={20} />
            </a>
          </Nav.Item>
        </Nav>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
