import { Image } from "react-bootstrap";
import notFound from "../assets/images/notfound.webp";
import "./styles/NotFound.css";
import quotes from "../assets/data/quotes.json";

function RandomQuote(): any {
  let min = 0;
  let max = quotes.length - 1;
  let i = Math.floor(Math.random() * (max - min + 1) + min);
  return quotes[i];
}

function NotFound(): JSX.Element {
  const currentQuote: any = RandomQuote();

  return (
    <div className="NotFound">
      <div className="NotFound__header">
        <h1>404</h1>
        <h2>Ups! La página no existe</h2>
      </div>
      <div className="NotFound__image">
        <Image
          src={notFound}
          srcSet={notFound}
          alt="Indya Logo"
          height="572"
          width="752"
          roundedCircle
          fluid
        />
      </div>
      <div className="NotFound__quoteContainer">
        <p className="quote">{currentQuote.quote}</p>
        <p className="cite">{currentQuote.author}</p>
      </div>
    </div>
  );
}

export default NotFound;
