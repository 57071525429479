import React from "react";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import SimpleHero from "../components/SimpleHero";
import ProductCard from "../components/ProductCard";
import data from "../assets/data/bush.json";

function BushFlowers(): JSX.Element {
  return (
    <React.Fragment>
      <SimpleHero
        title="Flores de Bush"
        description="Las Esencias Australianas de Bush son preparados obtenidos de flores de plantas que crecen de manera espontánea en Australia,
        en lugares libres de contaminación y cuyas propiedades son importantes para la persona en tratamiento"
        image="White-flowers.webp"
      />
      <Container>
        <Container>
          <h1>¿Qué son las flores de Bush?</h1>
          <p>
            Las Flores de Bush son un sistema floral unicista creado por Ian White a partir de la flora australiana (ABFE: Australian Bush Flower Essences).
            Existen 69 esencias florales de Bush, procedentes de flores australianas que ayudan a eliminar cualquier tipo de bloqueo energético.
          </p>
          <p>
            Actúan  desbloqueando los centros energéticos: Los siete más conocidos, son los que afectan a las glándulas y órganos principales, y se conocen como chakras maestros: cinco de ellos están sobre la columna vertebral y dos en la cabeza, todos ellos, en el eje vertical del cuerpo humano.
          </p>
          <br></br>
        </Container>
        <Container>
          <CardGroup className="ProductList">
            <Row xs={1} md={4} className="g-4 m-3">
              {data.Products.map((item) => (
                <Col key={item.id}>
                  <ProductCard item={item} />
                </Col>
              ))}
            </Row>
          </CardGroup>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default BushFlowers;
