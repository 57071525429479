import React from "react";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import SimpleHero from "../components/SimpleHero";
import ProductCard from "../components/ProductCard";
import data from "../assets/data/eyemasks.json";

function SoothingEyeMasks(): JSX.Element {
  return (
    <React.Fragment>
      <SimpleHero
        title="Máscaras para dormir"
        description="Máscaras de tela rellenas con semillas de lino y diversas hierbas"
        image="Pink-flowers.webp"
      />
      <Container>
        <Container>
          <h1>Almohadillas de semillas</h1>
          <p>
            Las almohadillas de semillas combinadas con flores y hierbas naturales, son un antiguo recurso para combatir el
            estrés y los dolores corporales, principalmente los de tipo
            muscular, gracias a su textura característica y forma es
            capaz de adaptarse a la anatomía del cuerpo,
            proporcionando un suave masaje y relajacion. Pueden ser utilizadas con frío y calor.

          </p>
          <br></br>
        </Container>
        <Container>
          <CardGroup className="ProductList">
            <Row xs={1} md={4} className="g-4 m-3">
              {data.Products.map((item) => (
                <Col key={item.id} className="col-lg-4 d-flex align-items-stretch">
                  <ProductCard item={item} />
                </Col>
              ))}
            </Row>
          </CardGroup>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default SoothingEyeMasks;
