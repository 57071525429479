import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";

export class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Hero />
        <Container className="px-4 py-5" id="hanging-icons">
          <h2 className="pb-2 border-bottom">Productos disponibles</h2>
          <Row className="g-4 py-5 row-cols-1 row-cols-lg-3">
            <Col className="d-flex align-items-start">
              <div className="bg-light text-dark flex-shrink-0 me-3">
              </div>
              <div>
                <h2>Flores de Bach</h2>
                <p>
                  Esencias florales que ayudan a equilibrar emociones, descubiertas por el Dr. Edward Bach. Se trabaja con 38 esencias florales de origen inglés.

                </p>
                <Link to="/bachflowers">
                  <Button variant="primary">Conocer más...</Button>
                  </Link>
              </div>
            </Col>
            <Col className="d-flex align-items-start">
              <div className="bg-light text-dark flex-shrink-0 me-3">
              </div>
              <div>
                <h2>Flores de Bush</h2>
                <p>
                  El Dr. Ian White investigó en los bosques australianos y descubrió 69 esencias florales,de rápida acción,
                  equilibrando así emociones y síntomas físicos.


                </p>
                <Link to="/bushflowers">
                  <Button variant="primary">Conocer más...</Button>
                  </Link>
              </div>
            </Col>
            <Col className="d-flex align-items-start">
              <div className="bg-light text-dark flex-shrink-0 me-3">
              </div>
              <div>
                <h2>Almohadillas de semillas</h2>
                <p>
                  Almohadillas artesanales elaboradas con semillas y flores secas, aromatizadas según cada necesidad. Se pueden usar con calor o con frío.

                </p>
                <Link to="/eyemasks">
                  <Button variant="primary">Conocer más...</Button>
                  </Link>
              </div>
            </Col>
            <Col className="d-flex align-items-start">
              <div className="bg-light text-dark flex-shrink-0 me-3">
              </div>
              <div>
                <h2>Velas</h2>
                <p>
                  Velas aromáticas de soja, iluminando espacios, 21 años de trayectoria.
                </p>
                <Link to="/candles">
                <Button variant="primary">Conocer más...</Button>
              </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;
