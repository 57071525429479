import React from "react";
import { Container, CardGroup, Col, Row } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import SimpleHero from "../components/SimpleHero";
import data from "../assets/data/courses.json";

function Courses(): JSX.Element {
  return (
    <React.Fragment>
      <SimpleHero
        title="Cursos"
        description="Cursos en línea que abordan distintas temáticas"
        image="nomeolvides.webp"
      />
      <Container>
        <Container>
          <CardGroup className="ProductList">
            <Row xs={1} md={4} className="g-4 m-3">
              {data.Products.map((item) => (
                <Col
                  key={item.id}
                  className="col-lg-3 d-flex align-items-stretch"
                >
                  <ProductCard item={item} />
                </Col>
              ))}
            </Row>
          </CardGroup>
        </Container>
        <Container>
          <br/>
          <h3>Medios de pago</h3>
          <p>Por el momento, los pagos se realizan únicamente con MercadoPago.</p>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default Courses;
