import React from "react";
import "./styles/SimpleHero.css";

function SimpleHero(props: {
  title: string | undefined;
  image: string | undefined;
  description: string | undefined;
}): JSX.Element {
  return (
    <React.Fragment>
      <div
        id="SimpleHero__image"
        title="Hero Image"
        className="SimpleHero d-flex flex-column justify-content-center px-4 py-5 my-4 text-center"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url(/images/${props.image})`
        }}
      >
        <h1 className="display-5 fw-bold" id="main_title">
          {props.title}
        </h1>
        <hr className="hr" style={{width:`50%`,margin:`1rem auto 1rem`}} />
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">{props.description}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SimpleHero;
