import React from "react";
import { Container, Image } from "react-bootstrap";
import SimpleHero from "../components/SimpleHero";
import "./styles/AboutUs.css";
import values from "../assets/images/ivana.webp";

function AboutUs(): JSX.Element {
  return (
    <React.Fragment>
      <SimpleHero
        title="Sobre mí"
        description="Seriedad, compromiso y trayectoria. Nos especializamos en terapia floral, sesiones de Reiki, tarot, lecturas y clases. Además, fabrico velas artesanales desde hace 21 años"
        image="Red_poppies.webp"
      />
      <Container className="AboutUs d-flex flex-column flex-wrap align-items-center">
        <h1>Sobre mí</h1>
        <br/>
        <section>
          <Image src={values} alt="Nuestros valores" height="80%" width="100%" />
          <p className="mx-2">
            Mi nombre es Ivana Medina Gerpe, soy terapeuta floral, amo la naturaleza.
            Participé en la Cumbre Mundial de Terapia Floral, México 2021.
            Sesiones y clases de Reiki.
            Lecturas de tarot y clases.
            Fabrico velas artesanales hace 21 años, llegue a vender mas de 10.000 unidades.
            Mi objetivo es compartir mis conocimientos de forma sencilla y practica, colaborando con el bienestar y aprendizaje de nuevos terapeutas.

          </p>
        </section>
        <br/>
      </Container>
    </React.Fragment>
  );
}

export default AboutUs;
