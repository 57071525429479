import React from "react";
import { Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";

function Layout(props: { children: any }): JSX.Element {
  return (
    <React.Fragment>
      <Header />
      <Container>{props.children}</Container>
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
