import React from "react";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import SimpleHero from "../components/SimpleHero";
import ProductCard from "../components/ProductCard";
import data from "../assets/data/candles.json";

function Candles(): JSX.Element {
  return (
    <React.Fragment>
      <SimpleHero
        title="Velas artesanales"
        description="Distintos diseños y materiales de origen a pedido"
        image="Pink-flowers.webp"
      />
      <Container>
        <Container>
          <h1>Velas artesanales Aries</h1>
          <p>
            Velas artesanales hechas a partir de soja, parafina, miel, con esencias aromáticas. Ideales para eventos, regalos empresariales, reuniones especiales, estas hermosas velas aportan luz, calidez y perfumarán el ambiente.
            Velas en perlas, sin residuos, flotante, se puede reutilizar cuando se apague, retire el pabilo junto con el restante de vela endurecida, se puede perfumar y pigmentar.

          </p>
          <br></br>
        </Container>
        <Container>
          <CardGroup className="ProductList">
            <Row xs={1} md={4} className="g-4 m-3">
              {data.Products.map((item) => (
                <Col key={item.id}>
                  <ProductCard item={item} />
                </Col>
              ))}
            </Row>
          </CardGroup>
        </Container>
      </Container>
    </React.Fragment>
  );
}

export default Candles;
