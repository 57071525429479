import { Button, Modal, Card } from "react-bootstrap";
import ProductItem from "../model/ProductItem";

function ProductCardDetails(props: { item: ProductItem, show:any, onHide:any  }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.item.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.item.subtitle}</h4>
        <Card.Img
          className="py-3 px-2"
          variant="top"
          src={`./images/${props.item.image}`}
          alt={props.item.alternativeText}
          width="100%"
          height="auto"
        />
        <p>
        {props.item.description}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductCardDetails;
